<template>
    <svg style="" v-bind:style="{width:radius*2+'px',height:radius*2+'px'}" id="svg" width="100%" height="100%"
         version="1.1"
         xmlns="http://www.w3.org/2000/svg">
        <!-- <circle :cx="indicator.x" :cy="indicator.y" :r="indicator.radius"
                :fill="activeColor"/> -->
        
        <text x="0" :x="label.x1" :y="label.y1" style="font-size:12px" :fill="defaultColor">累计完成</text>
        <text x="0" :x="label.x1" :y="label.y3" :fill="labelColor">{{leftValue}}</text>
        
        <text x="0" :x="label.x2" :y="label.y2" style="font-size:12px" :fill="defaultColor">销冠完成</text>
        <text x="0" :x="label.x2" :y="label.y4" :fill="labelColor">{{rightValue}}</text>
        
        <line v-for="item in lines" :x1="item.x1" :y1="item.y1" :x2="item.x2" :y2="item.y2"
              v-bind:style="{stroke:item.color}"
              style="stroke-width:2"/>
    </svg>
</template>
 
<script>
 
    export default {
        name: 'EnvProgress',
        props: {
            radius: {
                type: Number,
                default: 120
            },
            maxValue: {
                type: Number,
                default: 100
            },
            minValue: {
                type: Number,
                default: 0
            },
            leftValue: {
                type: String,
                default: '0'
            },
            rightValue: {
                type: String,
                default: '100'
            },
            value: {
                type: Number,
                default: 50
            },
            labelColor: {
                type: String,
                default: "#535353"
            },
            activeColor: {
                type: String,
                default: "#dace01"
            },
            defaultColor: {
                type: String,
                default: "#666974"
            },
            startAngle: {
                type: Number,
                default: 135
            },
            endAngle: {
                type: Number,
                default: 405
            },
            step: {
                type: Number,
                default: 5
            },
            strokeWidth: {
                type: Number,
                default: 15
            }
        },
        watch: {
            value(newValue) {
                console.log("newValue=" + newValue);
 
                newValue = parseInt(newValue);
                let progress = newValue / (this.maxValue - this.minValue);
                let range = this.endAngle - this.startAngle;
                let currentAngle = range * progress + this.startAngle;
                this.currentAngle = currentAngle;
                if (currentAngle < this.startAngle) {
                    currentAngle = this.startAngle
                }
                if (currentAngle > this.endAngle) {
                    currentAngle = this.endAngle
                }
//                console.log('value 变化了.oldvalue='+oldValue+",newValue="+newValue);
                this.startAnimate();
            }
        },
        methods: {
 
 
            startAnimate() {

                this.isAnimating = true;
                this.lines.forEach(v => {
                    v.color = this.defaultColor;
                });
                let self = this;
                self.i = 0;
 
                this.indicator.x = (this.radius + (self.radius - 5) * Math.cos(this.startAngle * Math.PI / 180));
                this.indicator.y = (this.radius + (self.radius - 5) * Math.sin(this.startAngle * Math.PI / 180));
 
 
                self.lines[self.i].color = self.activeColor;
                if(this.timer){
                    clearInterval(this.timer);
                }
                this.timer = setInterval(function () {
 
                    self.i++;
                    if (self.lines.length == self.i) {
                        self.isAnimating = false;
                        self.i = 0;
                        self.clearIn();
                        return
                    } else {
                        if (self.lines[self.i].angle <= self.currentAngle) {
                            self.indicator.x = (self.radius + (self.radius - 5) * Math.cos(self.lines[self.i].angle * Math.PI / 180));
                            self.indicator.y = (self.radius + (self.radius - 5) * Math.sin(self.lines[self.i].angle * Math.PI / 180));
                            self.lines[self.i].color = self.activeColor
                        } else {
                            self.lines[self.i].color = self.defaultColor
                        }
                    }
 
                }, 30)
            },
            clearIn() {
                console.log("clear");
                clearInterval(this.timer);
            },
            init() {
                //起始角度
                let startAngle = this.startAngle;
                //结束角度
                let endAngle = this.endAngle;
                //步长
                let step = this.step;
                //半径
                let r1 = this.radius;
                //半径2
                let r2 = r1 - this.strokeWidth;
 
 
                //进度
                let progress = this.value / (this.maxValue - this.minValue);
                let range = endAngle - startAngle;
                let currentAngle = range * progress + startAngle;
                this.currentAngle = currentAngle;
                if (currentAngle < startAngle) {
                    currentAngle = startAngle
                }
                if (currentAngle > endAngle) {
                    currentAngle = endAngle
                }
 
                this.indicator.x = (r1 + (r1 - 5) * Math.cos(currentAngle * Math.PI / 180));
                this.indicator.y = (r1 + (r1 - 5) * Math.sin(currentAngle * Math.PI / 180));
 
                for (let i = startAngle; i <= endAngle; i += step) {
                    let color = this.defaultColor;
 
                    if (i <= currentAngle) {
                        color = this.activeColor;
                    } else {
                        color = this.defaultColor;
                    }
                    let x = (r1 + (r1 - 11) * Math.cos(i * Math.PI / 180));
                    let y = (r1 + (r1 - 11) * Math.sin(i * Math.PI / 180));
 
                    let x2 = (r1 + (r2 - 11) * Math.cos(i * Math.PI / 180));
                    let y2 = (r1 + (r2 - 11) * Math.sin(i * Math.PI / 180));
 
                    this.lines.push({
                        angle: i,
                        x1: x,
                        y1: y,
                        x2: x2,
                        y2: y2,
                        color: color
                    })
                }
                this.label.x1 = (r1 + r1 * Math.cos(startAngle * Math.PI / 180));
                this.label.y1 = (r1 + r1 * Math.sin(startAngle * Math.PI / 180)) + 10;
                this.label.y3 = (r1 + r1 * Math.sin(startAngle * Math.PI / 180)) + 25;
 
                this.label.x2 = (r1 + r1 * Math.cos(endAngle * Math.PI / 180)) - 25;
                this.label.y2 = (r1 + r1 * Math.sin(endAngle * Math.PI / 180)) + 10;
                this.label.y4 = (r1 + r1 * Math.sin(endAngle * Math.PI / 180)) + 25;
 
 
            }
        },
        data() {
            return {
                lines: [],
                currentAngle: 0,
                label: {
                    x1: 0,
                    y1: 0,
                    y3: 0,
                    x2: 0,
                    y2: 0,
                    y4: 0,
                },
                timer: null,
                isAnimating: false,
                i: 0,
                indicator: {
                    x: 0,
                    y: 0,
                    radius: 5
                }
            }
        },
        mounted() {
            this.init();
        },
        beforeDestroy() {
            if (this.timer) {
                clearInterval(this.timer);
            }
        }
    }
</script>
 
<style scoped lang="less">
 
</style>